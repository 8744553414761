import React from 'react';
import PropTypes from 'prop-types';

import MilestonesArt from 'src/images/illustrations/milestones.svg';
import PlayArt from 'src/images/illustrations/play.svg';
import TechImage from 'src/images/illustrations/tech.svg';

function ServicesSection() {
  return (
  <div className="bg-gray-200">
    <div className="py-20 container mx-auto">
      <div className="font-serif font-light italic text-center text-4xl text-purple mb-8">
        What can I help with?
      </div>
      <div className="flex items-center flex-col lg:flex-row">
        <Service
          title="Milestones"
          text="Breaking down the milestones into smaller inch stones, so you can identify and celebrate the small things in life."
          image={MilestonesArt}
        />
        <Service
          title="Play"
          text="Intentional and purposeful play is a big part of all-round development. I at-home simple activities that can further your child’s skills."
          image={PlayArt}
        />
        <Service
          title="Assistive Technology"
          text="Early Mobility is key for cognitive &amp; language development. I strive to educate families on assistive tech to improve independence."
          image={TechImage}
        />
      </div>
    </div>
  </div>
  );
}

const Service = ({ title, text, image }) => (
  <div className="w-full lg:w-1/3 px-8 lg:px-4 xl:px-8 mb-8 lg:mb-0">
    <div className="block sm:flex lg:block p-10 bg-white shadow rounded text-center sm:text-left lg:text-center">
      <div className="w-full sm:w-1/3 lg:w-full mr-8 lg:mr-0">
        <img src={image} alt="" className="w-20 sm:w-full lg:px-20 mx-auto mb-8" />
      </div>
      <div>
        <div className="font-serif font-light italic text-2xl border-b border-dashed border-gray-400 pb-4 mb-4">
          {title}
        </div>
        <div className="text-lg">{text}</div>
      </div>
    </div>
  </div>
);
Service.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
};

export default ServicesSection;

import React from 'react';
import { Link } from 'gatsby';

import Layout from 'src/components/layout';
import SEO from 'src/components/seo';

import Banner from 'src/components/banner';
import Footer from 'src/components/footer';
import Instagram from 'src/components/instagram';
import Newsletter from 'src/components/newsletter';

import AboutSection from 'src/components/about';
import ServicesSection from 'src/components/services';

import SPImage from 'src/images/self/sp-home.jpg';

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`TinyTots PT`, `Shruti Pradhan`]}
        title="Home"
      />
      <Banner isHomePage={true}>
        Celebrating the uniqueness in{' '}
        <span className="border-b-4 border-yellow font-medium">every</span>{' '}
        child
      </Banner>
      <AboutSection title="Milestones can be confounding, and I’m here to simplify it for you." image={SPImage}>
        <div className="text-xl mb-4">
          Hello! I’m so glad you’re here!
        </div>
        <div className="text-xl mb-8">
          I’m Shruti Pradhan, a Pediatric Physical Therapist and am passionate about
          educating parents on motor development and play, as they navigate through different seasons of life.
        </div>
        <Link to="/about" className="inline-block border-2 border-purple font-bold text-purple rounded px-6 py-2">
          More about me
        </Link>
      </AboutSection>
      <ServicesSection />
      <Newsletter uid="1caa6ecafa" />
      <Instagram bgColor="gray-100" />
      <Footer />
    </Layout>
  );
}

export default IndexPage;
